<template>
  <main>
 
    <LoginPanel />
   
      <Map /> 
     <div class="mask"></div>
  </main>
</template>

<script>
import LoginPanel from "@/components/LoginPanel.vue";
import Map from "@/components/Map.vue";

export default {
  name: "login",
  components: {
    LoginPanel,
    Map
  }
};
</script>
